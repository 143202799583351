
import { defineComponent } from "vue";
import { Form, Field } from "vee-validate";
//import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "new-entity-course",
  components: {
    Form,
    Field,
  },
  data() {
    return {
      batch: {},
      employee: {},
      details: {},
      weekplan: {},
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      trainerSelected: [],
      trainer_ids: [],
      select_trainer_ids: [],
      trainerInfo: [
        {
          id: 1,
          name: "Zohirul Alam Tiemoon",
        },
        {
          id: 2,
          name: "Md. Nahidur Rahman Rifath",
        },
        {
          id: 3,
          name: "Md Abdul Alim",
        },
      ],
      loading: false,
      trainerInfoData: false,
      showCourseNotice: false,
      showTrainerList: false,
      showMapTrainerList: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.getEntityTypes();
    await this.getEntityInfos();
  },
  methods: {
    async formSubmit() {
      //form submit
    },
    async getEntityInfos() {
      await ApiService.get("entity/infos")
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityTypes() {
      await ApiService.get("entity/types")
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    selectedTrainer() {
      this.trainer_ids.map((item) => {
        var check = 0;
        this.trainerSelected.map((s_item) => {
          if (s_item['id'] == item['id']) {
            check = 1;
          }
         });

         for( var i = 0; i < this.trainerInfo.length; i++){ 
            if (this.trainerInfo[i]['id'] === item['id']) { 
                this.trainerInfo.splice(i, 1); 
            }
        }
         if(check == 0){
          this.trainerSelected.push(item);
         }
      });
    },
    unselectedTrainer() {
      this.select_trainer_ids.map((item) => {
        var check = 0;
        this.trainerInfo.map((s_item) => {
          if (s_item['id'] == item['id']) {
            check = 1;
          }
         });

         for( var i = 0; i < this.trainerSelected.length; i++){ 
            if (this.trainerSelected[i]['id'] === item['id']) { 
                this.trainerSelected.splice(i, 1); 
            }
        }
         if(check == 0){
          this.trainerInfo.push(item);
         }
      });
    },
    selectedAllTrainer() {
      Array.prototype.push.apply(this.trainerSelected,  this.trainerInfo);
      this.trainerInfo.splice(0);
    },
    unselectedAllTrainer() {
      Array.prototype.push.apply(this.trainerInfo,  this.trainerSelected);
      this.trainerSelected.splice(0);
    },
    trainerInfo() {
      this.trainerInfoData = true;
    },
    trainerList() {
      this.showTrainerList = true;
    },
    trainerMapList() {
      this.showMapTrainerList = true;
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
